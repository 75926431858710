import React from 'react'
import styles from './Link.module.css';

const Link = (props) => {
    return (
        <a className={styles.link} href={props.link} target="_blank">
            <img src={props.img} alt="link" />
        </a>
    )
}
export default Link;