import firebase from 'firebase';
import 'firebase/analytics';

export const firebaseConfig = {
  apiKey: "AIzaSyBtF1horE0Qf8MGYwm4GfC3FOKyo15KqQU",
  authDomain: "pdf-scaner-78781.firebaseapp.com",
  projectId: "pdf-scaner-78781",
  storageBucket: "pdf-scaner-78781.appspot.com",
  messagingSenderId: "548353226034",
  appId: "1:548353226034:web:572527002a609e9c16932b",
  measurementId: "G-L4LL1XYKTN"
};

firebase.initializeApp(firebaseConfig);

export const firebaseAnalytics = firebase.analytics();