import React from 'react';
import styles from './Main.module.css';
import Link from './Link/Link';
import googlePlay from '../../assets/img/app_store_button.svg';
import { useTranslation } from 'react-i18next';

const Main = () => {
  const [t, i18n] = useTranslation();
  return (
    <main className={styles.main}>
      <div className={styles.titles}>
        <h1>PDF Converter</h1>
        <h3>The best PDF Converter to convert your documents</h3>
      </div>
      <div className={styles.links}>
        <Link img={googlePlay} type="google"
              link={'https://apps.apple.com/us/app/pdf-converter-photo-to-pdf/id1644877682'}/>
      </div>
      <div className={styles.links}>
        <p>Support email: <a href={'mailto:support@captain.show'}>support@captain.show</a></p>
      </div>
    </main>
  );
};
export default Main;