import React from 'react';
import styles from './Privacy.module.css'
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const [t, i18n] = useTranslation();
    return (
        <main className={styles.main}>
            <div>
                <h3>
                    {t('Privacy Policy')}
                </h3>
                <p>
                    {t('Protecting Privacy')}
                </p>
            </div>
            <div>
                <h3>
                    {t("Privacy Cookies")}
                </h3>
                <p>
                    {t("Protecting Info Cookies")}
                </p>
            </div>
            <div>
                <h3>
                    {t("Privacy Ads")}
                </h3>
                <p>
                    {t("Privacy Ads Provider")}
                </p>
            </div>
            <div>
                <h3>
                    {t("Privacy Analytics")}
                </h3>
                <p>
                    {t("Privacy Analytics Description")}
                </p>
            </div>
            <div>
                <h3>
                    {t("Log file")}
                </h3>
                <p>
                    {t("Log file description")}
                </p>
            </div>
            <div>
                <h3>
                    {t('Contact us')}
                </h3>
                <p>
                    {t('Contact Us Details')}
                    <a href={'mailto:trickydev@gmail.com'}>trickydev@gmail.com</a>
                    {t('Contact Us Details 2')}
                </p>
            </div>
        </main>
    )
}
export default Privacy;