import React, {useEffect} from 'react';
import './App.css';
import "./i18n/i18n";
import { withTranslation } from "react-i18next";
import {firebaseAnalytics} from "./firebase-config";
import Main from "./components/Main/Main";
import {Route,Switch} from "react-router-dom";
import Privacy from "./components/Privacy/Privacy";
import TermsOfUse from "./components/TermOfUse/TermsOfUse";

const WithTranslationMain = withTranslation()(Main);

function App() {
  useEffect(() => {
    firebaseAnalytics.logEvent('page_visited')
  }, [])
  return (
      <div className="app">
        <div className="image">
          <Switch>
            <Route exact path={'/'} render={() => <WithTranslationMain/>} />
            <Route path={'/privacy'} render={() => <Privacy/>} />
            <Route path={'/termsofuse'} render={() => <TermsOfUse/>} />
          </Switch>
        </div>
      </div>
  );
}

export default App